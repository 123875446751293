import { call } from 'redux-saga/effects';

import { INFINITY_SCROLL } from 'constant/InfinityScrollConst';
import { CHART_THIRTY_SEC_CONST } from 'constant/ChartConst';

import ApiManager from 'network/ApiManager';

import {
  convertBlobToRaw,
  convertWaveformIndexToByte,
} from './BinaryFileLoadWorker';

export class EventReviewPreSignedUrl {
  constructor(action = {}, context = {}) {
    const {
      atTime,
      secStep,
      isBackward,
      isForward,
      isInit,
      isJumpToTime,
      isScroll,
      withBeat = false,
      isBeatStrip = false,
    } = action;

    this.action = {
      atTime,
      secStep,
      isBackward,
      isForward,
      isInit,
      isJumpToTime,
      isScroll,
      withBeat,
      isBeatStrip,
    };

    this.context = context;
  }

  validate() {
    const { atTime } = this.action;
    if (isNaN(atTime)) {
      throw new Error('atTime is not a number?');
    }
  }

  calculateQueryTimes() {
    const { atTime, secStep, isScroll, isJumpToTime, isBackward, isForward } =
      this.action;
    const { recordingStartMs, recordingEndMs } = this.context;

    const formatMsAtTime = parseInt(
      atTime.toString().slice(0, 9).padEnd(13, 0)
    );

    let onsetMsQueryStr = formatMsAtTime;
    let terminationMsQueryStr = formatMsAtTime + secStep;

    if (!isScroll) {
      const offsetTime = isJumpToTime
        ? INFINITY_SCROLL.EXPLORER_UNIT_MS.OFFSET_ROW
        : 0;
      onsetMsQueryStr -= offsetTime;

      // onsetMsQueryStr가 recordingStartMs보다 작아지지 않도록 보정
      onsetMsQueryStr = Math.max(onsetMsQueryStr, recordingStartMs);

      if (recordingStartMs > terminationMsQueryStr) {
        throw new Error('Termination time is before the start time.');
      }
      if (terminationMsQueryStr > recordingEndMs) {
        terminationMsQueryStr = recordingEndMs;
      }
    }

    if (isScroll) {
      // onsetMsQueryStr가 recordingStartMs보다 작아지지 않도록 보정
      onsetMsQueryStr = Math.max(onsetMsQueryStr, recordingStartMs);

      if (isBackward && !isForward) {
        if (recordingStartMs > terminationMsQueryStr) {
          throw new Error('Termination time is before the start time.');
        }
      } else if (isForward && !isBackward) {
        if (recordingEndMs < onsetMsQueryStr) {
          throw new Error('Onset time is after the end time.');
        }
      }
    }

    return { onsetMsQueryStr, terminationMsQueryStr };
  }

  calculateByteRange(onsetMsQueryStr, terminationMsQueryStr, recordingStartMs) {
    return {
      begin: convertWaveformIndexToByte(
        (onsetMsQueryStr - recordingStartMs) / 4
      ),
      end: convertWaveformIndexToByte(
        (terminationMsQueryStr - recordingStartMs) / 4
      ),
    };
  }

  // 처음 event review 탭에 진입 했을 때 앞뒤의 시간 영역을 구하는 함수
  calculateTimeRanges({
    onsetMsQueryStr,
    terminationMsQueryStr,
    recordingStartMs,
    recordingEndMs,
  }) {
    let backwardOnsetMsQueryStr = onsetMsQueryStr - 1000 * 60 * 5;
    let backwardTerminationMsQueryStr = onsetMsQueryStr;
    let forwardOnsetMsQueryStr = terminationMsQueryStr;
    let forwardTerminationMsQueryStr = terminationMsQueryStr + 1000 * 60 * 5;

    // reset backward Onset time, forward Termination time
    if (backwardOnsetMsQueryStr < recordingStartMs) {
      backwardOnsetMsQueryStr = recordingStartMs;
    }
    if (forwardTerminationMsQueryStr > recordingEndMs) {
      forwardTerminationMsQueryStr = recordingEndMs;
    }

    return {
      backwardOnsetMsQueryStr,
      backwardTerminationMsQueryStr,
      forwardOnsetMsQueryStr,
      forwardTerminationMsQueryStr,
    };
  }

  mergeRawDataList(newData, existingData) {
    const { isScroll, isBackward, isForward } = this.action;

    if (isScroll && isBackward) {
      return [...newData, ...existingData];
    }
    if (isScroll && isForward) {
      return [...existingData, ...newData];
    }
  }

  transformRawList = (ecgRawList) => {
    const result = ecgRawList?.map((raw) => {
      raw.createAt = new Date().getTime();
      raw.baselineWaveformIndex = raw.onsetWaveformIndex;
      return raw;
    });

    return result;
  };
  // OPFS를 사용할 때 EcgRaw 데이터를 세팅하는 함수
  processEcgRawData({
    raw,
    onsetMsQueryStr,
    terminationMsQueryStr,
    recordingStartMs,
  }) {
    const thirtyChartWfi = CHART_THIRTY_SEC_CONST.waveFormIndexLength;
    // raw 데이터를 30초 차트 길이로 나눠  30초 차트 갯수 카운트
    const numberOf30sChart = raw.length / thirtyChartWfi;
    const onsetWaveformIndex = Math.floor(
      (onsetMsQueryStr - recordingStartMs) / 4
    );

    const results = [];
    for (let i = 0; i < numberOf30sChart; i++) {
      const ecgDataChunk = raw.slice(
        thirtyChartWfi * i,
        thirtyChartWfi * i + thirtyChartWfi
      );
      const baselineWaveformIndex = onsetWaveformIndex;

      // 각 chunk에 대해 객체 생성
      const result = {
        onsetMs: onsetMsQueryStr + thirtyChartWfi * i * 4,
        terminationMs:
          onsetMsQueryStr + thirtyChartWfi * i * 4 + thirtyChartWfi * 4,
        onsetWaveformIndex: onsetWaveformIndex + thirtyChartWfi * i,
        terminationWaveformIndex:
          onsetWaveformIndex + thirtyChartWfi * i + thirtyChartWfi,
        ecgData: ecgDataChunk,
        baselineWaveformIndex,
      };

      results.push(result);
    }

    return results;
  }

  // OPFS가능 여부에 따른 fetchEcg 분기
  *fetchEcgRawData({
    isAvailableOPFS,
    rawBlob,
    onsetMsQueryStr,
    terminationMsQueryStr,
    recordingStartMs,
  }) {
    let results;
    if (isAvailableOPFS) {
      results = yield this.fetchEcgRawDataByOPFS({
        onsetMsQueryStr,
        terminationMsQueryStr,
        recordingStartMs,
        rawBlob,
      });
    } else {
      results = yield this.fetchEcgRawDataByAPI({
        onsetMsQueryStr,
        terminationMsQueryStr,
      });
    }
    return results;
  }

  // OPFS 사용 또는 API 호출을 통해 앞,뒤 영역의 EcgRaw를 받는다.
  *getExtraEcgRawData({
    recordingEndMs,
    backwardOnsetMsQueryStr,
    backwardTerminationMsQueryStr,
    forwardOnsetMsQueryStr,
    forwardTerminationMsQueryStr,
    recordingStartMs,
    isAvailableOPFS,
    rawBlob,
  }) {
    let result;
    if (isAvailableOPFS) {
      result = yield this.getExtraEcgRawByOPFS({
        backwardOnsetMsQueryStr,
        backwardTerminationMsQueryStr,
        forwardOnsetMsQueryStr,
        forwardTerminationMsQueryStr,
        recordingStartMs,
        recordingEndMs,
        rawBlob,
      });
    } else {
      result = yield this.getExtraEcgRawByApi({
        backwardOnsetMsQueryStr,
        backwardTerminationMsQueryStr,
        forwardOnsetMsQueryStr,
        forwardTerminationMsQueryStr,
        recordingStartMs,
        recordingEndMs,
      });
    }
    return result;
  }

  // API 호출을 통해 앞,뒤 영역의 이벤트 데이터를 받는다.
  *getExtraEcgRawByApi({
    backwardOnsetMsQueryStr,
    backwardTerminationMsQueryStr,
    forwardOnsetMsQueryStr,
    forwardTerminationMsQueryStr,
    recordingStartMs,
    recordingEndMs,
  }) {
    let result = { backward: [], forward: [] };
    if (backwardTerminationMsQueryStr > recordingStartMs) {
      const resultBackward = yield this.fetchEcgRawDataByAPI({
        onsetMsQueryStr: backwardOnsetMsQueryStr,
        terminationMsQueryStr: backwardTerminationMsQueryStr,
      });
      result.backward = resultBackward;
    }
    if (forwardOnsetMsQueryStr < recordingEndMs) {
      const resultForward = yield this.fetchEcgRawDataByAPI({
        onsetMsQueryStr: forwardOnsetMsQueryStr,
        terminationMsQueryStr: forwardTerminationMsQueryStr,
      });
      result.forward = resultForward;
    }
    return result;
  }

  // OPFS를 통해 앞,뒤 영역의 이벤트 데이터를 받는다.
  *getExtraEcgRawByOPFS({
    backwardOnsetMsQueryStr,
    backwardTerminationMsQueryStr,
    forwardOnsetMsQueryStr,
    forwardTerminationMsQueryStr,
    recordingStartMs,
    recordingEndMs,
    rawBlob,
  }) {
    let result = { backward: [], forward: [] };
    if (backwardTerminationMsQueryStr > recordingStartMs) {
      const resultBackward = yield this.fetchEcgRawDataByOPFS({
        onsetMsQueryStr: backwardOnsetMsQueryStr,
        terminationMsQueryStr: backwardTerminationMsQueryStr,
        recordingStartMs,
        rawBlob,
      });
      result.backward = resultBackward;
    }
    if (forwardOnsetMsQueryStr < recordingEndMs) {
      const resultForward = yield this.fetchEcgRawDataByOPFS({
        onsetMsQueryStr: forwardOnsetMsQueryStr,
        terminationMsQueryStr: forwardTerminationMsQueryStr,
        recordingStartMs,
        rawBlob,
      });
      result.forward = resultForward;
    }

    return result;
  }

  // OPFS를 사용할 때 fetch 함수
  *fetchEcgRawDataByOPFS({
    onsetMsQueryStr,
    terminationMsQueryStr,
    recordingStartMs,
    rawBlob,
  }) {
    const range = this.calculateByteRange(
      onsetMsQueryStr,
      terminationMsQueryStr,
      recordingStartMs
    );
    const raw = yield call(convertBlobToRaw, { blob: rawBlob, range });
    const result = this.processEcgRawData({
      raw,
      onsetMsQueryStr,
      terminationMsQueryStr,
      recordingStartMs,
    });
    return result;
  }

  // API를 사용할 때 fetch 함수
  *fetchEcgRawDataByAPI({ onsetMsQueryStr, terminationMsQueryStr }) {
    const { ecgTestId } = this.context;
    const { isBeatStrip } = this.action;
    const {
      data: { results },
    } = yield call(ApiManager.getRawEcg, {
      ecgTestId,
      onsetMs: onsetMsQueryStr,
      terminationMs: terminationMsQueryStr,
      withBeat: isBeatStrip,
      withRaw: false,
    });
    return results;
  }
}
